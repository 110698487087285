<template>
  <div>
    <div>
      <BaseInput
        ref="amountInputRef"
        v-model="amount"
        label="I want to pay"
        placeholder="Enter amount"
      >
      </BaseInput>
    </div>
    <div class="mt-3">
      <Label class="mb-1.5 text-sm font-medium text-primary"
        >Over a period of</Label
      >
      <div v-if="pricingDetails" class="flex flex-wrap items-center gap-2">
        <Button
          v-for="price in pricingDetails"
          :key="price.id"
          :variant="selectedPrice?.id === price.id ? 'default' : 'outline'"
          :value="price.id"
          @click.prevent="selectedPrice = price"
        >
          {{ price.repayment_period }}</Button
        >
      </div>
      <Skeleton v-else class="h-20" />
    </div>
    <div
      class="mt-11 flex items-center justify-center bg-slate-50 py-6 font-bold text-primary"
    >
      <div class="text-center">
        <p class="text-lg uppercase">MONTHLY REPAYMENT</p>
        <div class="text-[2.5rem]">
          {{
            new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 2,
            }).format(calculatedResult.monthlyPayment || 0)
          }}
        </div>
      </div>
    </div>

    <div
      class="mt-3.5 flex items-center justify-between font-medium text-primary"
    >
      <p class="text-base">TOTAL REPAYMENT</p>
      <div class="text-lg">
        {{
          new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 2,
          }).format(calculatedResult.totalRepayable || 0)
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/vue-query'
import { watchDeep } from '@vueuse/core'
import { useProfileStore } from '~/stores/profile'
import type { BillPaymentPricing } from '~/types/models/billPaymentLoans.model'
import { initializeInputsForVCleave } from '~/lib/utils'
import type BaseInput from '~/components/Base/BaseInput.vue'
import Skeleton from '~/components/ui/skeleton/Skeleton.vue'

const { $api, $event } = useNuxtApp()
const amount = ref('')
const profile = useProfileStore()
const { organisationId } = storeToRefs(profile)

const repaymentPeriodMap: { [key: string]: number } = {
  '1 Month': 1,
  '2 Months': 2,
  '3 Months': 3,
  '4 Months': 4,
  '5 Months': 5,
  '6 Months': 6,
  '7 Months': 7,
  '8 Months': 8,
  '9 Months': 9,
  '10 Months': 10,
  '11 Months': 11,
  '12 Months': 12,
}

const { data: pricingDetails } = useQuery({
  queryKey: ['getBillPaymentPricingDetails', organisationId.value],
  queryFn: () =>
    $api.banking.billPaymentLoans.getBillPaymentPricingDetails({
      organisationId: organisationId.value!,
      paymentDate: dayjs().format('YYYY-MM-DD'),
    }),
  enabled: computed(() => !!organisationId.value),
  select(data) {
    return data.data
  },
})

const selectedPrice = ref<BillPaymentPricing>()
const calculatedResult = computed<{
  totalRepayable: number
  monthlyPayment: number
}>(() => {
  if (!selectedPrice.value) return { totalRepayable: 0, monthlyPayment: 0 }
  const refinedAmount = +amount.value.replace('£', '').replaceAll(',', '')
  const refinedNumber = Number(refinedAmount)
  const divide = repaymentPeriodMap[selectedPrice.value.repayment_period] || 0

  const fees = selectedPrice.value.fee * refinedAmount
  const totalRepayable = refinedNumber + fees

  return {
    totalRepayable,
    monthlyPayment: totalRepayable / divide,
  }
})

const amountInputRef = ref<InstanceType<typeof BaseInput>>()
onMounted(() => {
  if (amountInputRef.value) {
    initializeInputsForVCleave(amountInputRef, {
      ...postiveOnlyNumeralMask,
      prefix: '£ ',
    })
  }

  $event('track:mixpanel', {
    event: 'Calcuator opened',
    data: {},
  })
})

watchDeep(selectedPrice, (newVal) => {
  if (!newVal) return
  if (!amount.value) return

  $event('track:mixpanel', {
    event: 'Calcuator: duration changed',
    data: newVal,
  })
})
</script>
